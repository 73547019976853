import translate from "./translate"

const TUIContact = {
  "群聊通知": "群聊通知",
  "系统通知": "系统通知",
  "我的群聊": "我的群聊",
  "输入ID": "请输入用户/群聊 ID，回车搜索",
  "群名称": "群名称",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "进入群聊": "进入群聊",
  "群类型": "群类型",
  "请填写验证信息": "请填写验证信息",
  "申请加入": "申请加入",
  "已申请":"已申请",
  "接受": "接受",
  "拒绝": "拒绝",
  "已处理": "已处理",
  "添加群聊": "添加群聊",
  "已加入": "已加入",
  "该群组不存在": "该群组不存在",
  "加群": "加群",
  "我的好友": "我的好友",
  "发送消息": "发送消息",
  "个性签名": "个性签名",
  "等待管理员同意": "申请成功，等待管理员同意入群申请",
  "加群成功": "加群成功",
  "您已是群成员": "您已是群成员",
  "新的联系人": "新的联系人",
  "黑名单": "黑名单",
  "无搜索结果": "无搜索结果",
  "客服号": "客服号",
}

export default TUIContact;
