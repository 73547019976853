import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=3a782de5&scoped=true"
import script from "./Menu.vue?vue&type=script&lang=ts&setup=true"
export * from "./Menu.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Menu.vue?vue&type=style&index=0&id=3a782de5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a782de5",
  null
  
)

export default component.exports