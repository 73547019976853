const time = {
  "周": "周",
  "天": "天",
  "小时": "小时",
  "分钟": "分钟",
  "刚刚": "刚刚",
  "前": "前",
  "昨天": "昨天",
  "星期一": "星期一",
  "星期二": "星期二",
  "星期三": "星期三",
  "星期四": "星期四",
  "星期五": "星期五",
  "星期六": "星期六",
  "星期日": "星期日"
}

export default time;
