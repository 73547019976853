const time = {
  "周": "week(s) ",
  "天": "day(s)",
  "小时": "hour(s) ",
  "分钟": "minute(s)",
  "刚刚": "Just now",
  "前": " ago",
  "昨天": "Yesterday",
  "星期一": "Monday",
  "星期二": "Tuesday",
  "星期三": "Wednesday",
  "星期四": "Thursday",
  "星期五": "Friday",
  "星期六": "Saturday",
  "星期日": "Sunday"
}

export default time;
