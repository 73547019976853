var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"avatar-container",style:({
    width: _setup.avatarSize,
    height: _setup.avatarSize,
    borderRadius: _setup.avatarBorderRadius,
  })},[(_setup.isUniFrameWork)?_c('image',{staticClass:"avatar-image",attrs:{"src":_setup.avatarImageUrl || _setup.defaultAvatarUrl},on:{"load":_setup.avatarLoadSuccess,"error":_setup.avatarLoadFailed}}):_c('img',{staticClass:"avatar-image",attrs:{"src":_setup.avatarImageUrl || _setup.defaultAvatarUrl},on:{"load":_setup.avatarLoadSuccess,"error":_setup.avatarLoadFailed}}),(_setup.useAvatarSkeletonAnimation)?_c('div',{class:{
      placeholder: true,
      hidden: _setup.isImgLoaded,
      'skeleton-animation': _setup.useAvatarSkeletonAnimation
    }}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }